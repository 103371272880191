@font-face {
font-family: '__GeistMono_8a4d12';
src: url(/_next/static/media/f980ec13b5b5e554.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}.__className_8a4d12 {font-family: '__GeistMono_8a4d12', ui-monospace, SFMono-Regular, Roboto Mono, Menlo, Monaco, Liberation Mono, DejaVu Sans Mono, Courier New, monospace
}.__variable_8a4d12 {--font-geist-mono: '__GeistMono_8a4d12', ui-monospace, SFMono-Regular, Roboto Mono, Menlo, Monaco, Liberation Mono, DejaVu Sans Mono, Courier New, monospace
}

@font-face {
font-family: '__GeistSans_ac79ff';
src: url(/_next/static/media/ec1a1eae803b668e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__GeistSans_Fallback_ac79ff';src: local("Arial");ascent-override: 90.59%;descent-override: 21.66%;line-gap-override: 9.85%;size-adjust: 101.56%
}.__className_ac79ff {font-family: '__GeistSans_ac79ff', '__GeistSans_Fallback_ac79ff'
}.__variable_ac79ff {--font-geist-sans: '__GeistSans_ac79ff', '__GeistSans_Fallback_ac79ff'
}

